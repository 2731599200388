import axios from "@/utils/request.js";
import OSS from "ali-oss";
import { reportWebVitals } from "@/utils/ga.js";
// import Notify from "vant/lib/notify";
// Notify({
// 	type: "danger",
// 	message: 1111
// });

const uploadPhotoUrl = "/parsePhoto";
const finalPhotoUrl = "/getFinalPhoto"
const ossUrl = "/aliSTS"

let ossToken = null

// const stsUrl = "https://5232636716517157.cn-hongkong.fc.aliyuncs.com/2016-08-15/proxy/gpp-fc-service-dev/aliSTS/";
export async function uploadPhoto(path) {

	const time = new Date().getTime();
	const photoKey = `public/photos/${time}.jpg`;
	let signedUrl = "";
	let count = 0
	return new Promise((resolve, reject) => {
		async function getClientInfo() {
			count++
			try {
				// 'object'填写上传至OSS的object名称,即不包括Bucket名称在内的Object的完整路径。
				// 'localfile'填写上传至OSS的本地文件完整路径。
				const oss = await getClient();
				await oss.put(photoKey, path);
				signedUrl = oss.signatureUrl(photoKey, { expires: 300 });
				// 上传图片消耗时间
				const endTime = new Date().getTime() - time;
				reportWebVitals("upload_photo_time", endTime, "", "time");
				resolve({ photoKey, signedUrl });
			} catch (e) {
				console.log("error: ", e);
				console.log("error name: ", e.name);
				if (e.name == "InvalidAccessKeyIdError" && count < 4) {
					console.log("in>>>>")
					ossToken = null
					getClientInfo()
				} else {
					reject(e);
				}
			}
		}
		getClientInfo();
	});
}

async function getClient() {
	console.log("getClient>>")
	// 设置客户端请求访问凭证的地址。
	let oss = "";
	try {
		if (ossToken == null) {
			console.log("token is null")
			const res = await axios({
				method: "post",
				url: ossUrl,
			});

			if (res.body.code != 0) {
				// 上传图片失败打点
				reportWebVitals("upload_fail_start", "", "parse", "", {
					code: res.body.code,
				});
				return;
			}
			ossToken = res.body.data;
		}
		oss = new OSS({
			// yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
			region: ossToken.region,
			accessKeyId: ossToken.AccessKeyId,
			accessKeySecret: ossToken.AccessKeySecret,
			stsToken: ossToken.SecurityToken,
			// 填写Bucket名称，例如examplebucket。
			bucket: ossToken.bucket
		});
	} catch (error) {
		console.error(error);
	}

	return oss;
}

export function parsePhoto(url, photoKey, curDocumentInfo) {
	return axios({
		method: "post",
		url: uploadPhotoUrl,
		data: {
			url,
			photoKey,
			width: curDocumentInfo.width,
			height: curDocumentInfo.height,
			background: curDocumentInfo.background_color_code
		}
	});
}
export function getFinalPhoto(orderId, photoKey) {
	return axios({
		method: "post",
		url: finalPhotoUrl,
		data: {
			orderID: orderId,
			photoKey
		}
	});
}